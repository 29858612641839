import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { Text } from '@oneaudi/unified-web-components';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';

import {
  SYS_BREAKPOINT_LG,
  SYS_SPACE_RELATIVE_4_XL,
  SYS_SPACE_RELATIVE_MD,
  SYS_SPACE_RELATIVE_XS,
} from '@oneaudi/unified-web-common';
import { Context } from '../../Context';
import { Disclaimers } from './Disclaimers';
import { ConsumptionAndEmissions } from './ConsumptionAndEmissions';

export interface StyledLegalWrapperProps {
  isLandscapeSize?: boolean;
}

const StyledCaption = styled(Text)`
  padding-bottom: ${SYS_SPACE_RELATIVE_XS};
`;

const StyledLegalInfo = styled.div<StyledLegalWrapperProps>`
  height: auto;

  ${(props) => {
    return `
      flex: ${props.isLandscapeSize ? '2' : '1'} 1 0;
    `;
  }}

  @media (min-width: calc(${SYS_BREAKPOINT_LG} + 1px)) {
    :nth-child(2n) {
      margin-left: ${SYS_SPACE_RELATIVE_4_XL};
    }
  }

  margin-top: ${SYS_SPACE_RELATIVE_MD};
  margin-bottom: ${SYS_SPACE_RELATIVE_MD};
`;

export interface LegalInfoProps {
  isLandscapeSize?: boolean;
  caption?: string;
  consumptionAndEmissions?: string[];
  disclaimers?: string[];
  position: 'left' | 'right';
}

export const LegalInfo: React.FC<LegalInfoProps> = ({
  isLandscapeSize,
  caption,
  consumptionAndEmissions,
  disclaimers,
  position,
}) => {
  const { localeService, vueFormatterService } = useContext(Context);

  const ueProperty = position === 'left' ? 'media1Option_caption' : 'media2Option_caption';
  const ueLabel = position === 'left' ? '#1 Media (Left)' : '"#2 Media (Right)';

  return (
    <StyledLegalInfo
      className="legal-info"
      data-testid="legal-info-section"
      isLandscapeSize={isLandscapeSize}
    >
      {caption && (
        <StyledCaption variant={{ order: '2', style: 'normal' }}>
          <UeElement type="text" property={ueProperty} label={`${ueLabel} Caption`}>
            {renderTextWithFootnotesReferencesV2(caption)}
          </UeElement>
        </StyledCaption>
      )}
      {vueFormatterService && localeService && (
        <ConsumptionAndEmissions
          caeIds={consumptionAndEmissions}
          vueFormatterService={vueFormatterService}
          localeService={localeService}
        />
      )}
      <Disclaimers disclaimers={disclaimers} />
    </StyledLegalInfo>
  );
};

LegalInfo.displayName = 'LegalInfo';
