/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  SYS_BREAKPOINT_2_XL,
  SYS_BREAKPOINT_LG,
  SYS_BREAKPOINT_MD,
  SYS_BREAKPOINT_SM,
  SYS_BREAKPOINT_XL,
  SYS_SPACE_RELATIVE_3_XL,
  SYS_SPACE_RELATIVE_XL,
} from '@oneaudi/unified-web-common';
import styled from 'styled-components';

export interface StyledEditorialMediaContainerProps {
  hasMarginTop?: boolean;
  hasMarginBottom?: boolean;
}

export const StyledEditorialMediaContainer = styled.div<StyledEditorialMediaContainerProps>`
  sup {
    // footnotes too small fix
    font-size: 9px !important;
    line-height: initial;
  }

  ${(props) => {
    return `
      background-color: ${props.theme.global.color.canvas.default};
      padding-top: ${props.hasMarginTop ? SYS_SPACE_RELATIVE_3_XL : 0};
      padding-bottom: ${props.hasMarginBottom ? SYS_SPACE_RELATIVE_XL : 0};
      padding-left: var(--page-margin);
      padding-right: var(--page-margin);
    `;
  }}

  @media (min-width: ${SYS_BREAKPOINT_LG}) {
    flex-direction: row;

    img {
      height: 100%;
    }
  }

  .media-image {
    width: 100%;
    object-fit: cover;
    display: none;

    &.screen-size-xs {
      @media only screen and (max-width: calc(${SYS_BREAKPOINT_SM} - 1px)) {
        display: block;
      }
    }

    &.screen-size-s {
      @media only screen and (min-width: ${SYS_BREAKPOINT_SM}) and (max-width: calc(${SYS_BREAKPOINT_MD} - 1px)) {
        display: block;
      }
    }

    &.screen-size-m {
      @media only screen and (min-width: ${SYS_BREAKPOINT_MD}) and (max-width: calc(${SYS_BREAKPOINT_LG} - 1px)) {
        display: block;
      }
    }

    &.screen-size-l {
      @media only screen and (min-width: ${SYS_BREAKPOINT_LG}) and (max-width: calc(${SYS_BREAKPOINT_XL} - 1px)) {
        display: block;
      }
    }

    &.screen-size-xl {
      @media only screen and (min-width: ${SYS_BREAKPOINT_XL}) and (max-width: calc(${SYS_BREAKPOINT_2_XL} - 1px)) {
        display: block;
      }
    }

    &.screen-size-xxl {
      @media only screen and (min-width: ${SYS_BREAKPOINT_2_XL}) {
        display: block;
      }
    }
  }
`;
