import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { FeatureAppLoader } from '@feature-hub/react';
import { AssetTypeVideo, videoPlayerFeatureAppParams, VIDEO_PLAYER_FA_NAME } from '../../types';
import { onElementAvailableByTagName } from '../../utils/onElementAvailableByTagName';
import { getVideoPlayerConfig } from '../../utils/getVideoPlayerConfig';
import { Context } from '../../Context';
import { debug, logVideoPlayerComponentTrackingData, observeChildren } from '../../utils/debugging';

export interface VideoWrapperProps {
  preserveVideoWidth?: boolean;
}

const VideoWrapper = styled.div<VideoWrapperProps>`
  width: 100%;

  ${(props) => {
    return `
    height: ${props.preserveVideoWidth ? 'auto' : '100%'};
      `;
  }}
`;

export interface VideoProps {
  video: AssetTypeVideo;
  position: 'left' | 'right';
}

export const Video: React.FC<VideoProps> = (props) => {
  const { video, position } = props;
  const videoPlayerConfig = useMemo(() => getVideoPlayerConfig(video), [video]);
  const { /* videoService, */ featureAppEnv } = useContext(Context);
  const videoRef = React.useRef<HTMLDivElement>(null);
  const videoPlayerId = useMemo(
    () => `${featureAppEnv.featureAppId}_${VIDEO_PLAYER_FA_NAME}-${position}`,
    [featureAppEnv, VIDEO_PLAYER_FA_NAME, position],
  );

  const setVideoEvents = useCallback(() => {
    if (!videoRef || !videoRef.current || !videoRef.current.querySelector('video')) {
      debug('setVideoEvents: video element not found!');
    }

    onElementAvailableByTagName('video', videoRef, (videoElements) => {
      videoElements[0].addEventListener('play', () => {
        logVideoPlayerComponentTrackingData('PLAY');
      });
      videoElements[0].addEventListener('pause', () => {
        logVideoPlayerComponentTrackingData('PAUSE');
      });
      videoElements[0].addEventListener('ended', () => {
        logVideoPlayerComponentTrackingData('ENDED');
      });
    });

    logVideoPlayerComponentTrackingData('setVideoEvents');
  }, []);

  useEffect(() => {
    debug('mount video wrapper');

    if (videoRef && videoRef.current) {
      observeChildren(videoRef.current);
    } else {
      debug('   wrapper element not found!');
    }

    return (): void => {
      debug('unmount video wrapper');
    };
  }, []);

  useEffect(() => {
    debug('Video player loaded with config ', videoPlayerConfig);
  }, []);

  return (
    <VideoWrapper
      onLoad={setVideoEvents}
      ref={videoRef}
      preserveVideoWidth={video.preserveVideoWidth}
    >
      <FeatureAppLoader
        featureAppId={videoPlayerId}
        featureAppName={VIDEO_PLAYER_FA_NAME}
        config={videoPlayerConfig}
        {...videoPlayerFeatureAppParams}
      />
    </VideoWrapper>
  );
};
