import { FA_LOCATOR } from '../types';

/**
 * Get the instance index number of the current feature app instance on the current page.
 *
 * Most of the time the index will be "0" when there's only one feature app instance on
 * the current web page. But if there are more instances present, this function returns
 * the index number according to the order of all instances on the page.
 *
 * @param ref React reference to the DOM element of the feature app
 * @returns instance index number of the feature app
 */
export function getInstanceIndexNo(ref: React.MutableRefObject<null>): number {
  const quoteElements = document.querySelectorAll(FA_LOCATOR);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < quoteElements.length; i++) {
    if (quoteElements[i] === ref.current) {
      return i;
    }
  }

  return 0;
}
