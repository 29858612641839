import { AssetTypeVideo } from '../types';
import { parseAssetValue } from './parseAssetValue';

type BreakpointLabel = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

type Breakpoints<T> = {
  [label in BreakpointLabel]: T;
};

export type Video = {
  alt: string;
  aspectRatio: string;
  poster?: string;
  src: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  nativeControls?: boolean;
};

export type VideoPlayerConfig = {
  videos: Partial<Breakpoints<Video>>;
  playsInline: boolean;
  cover?: boolean;
  isFormatable?: boolean;
  groupButtonsMobile?: boolean;
};

export function getVideoPlayerConfig(video: AssetTypeVideo): VideoPlayerConfig {
  const parsedPoster = parseAssetValue(video.poster?.assetValue).src;
  const parsedSrc = parseAssetValue(video.src.assetValue);
  const parsedPortrait =
    video.srcPortrait && video.srcPortrait.assetValue
      ? parseAssetValue(video.srcPortrait.assetValue)
      : undefined;

  // separately provided poster image url overrides the VMS thumbnail image used as poster image
  const posterSrc = parsedPoster || parsedSrc.posterSrc;

  if (parsedPortrait) {
    return {
      cover: true,
      playsInline: true,
      groupButtonsMobile: false,
      videos: {
        xs: {
          alt: video.alt,
          poster: posterSrc,
          aspectRatio: '3/4',
          autoPlay: video.autoPlay,
          loop: video.loop,
          muted: video.muted,
          src: parsedPortrait.src,
          nativeControls: video.controls,
        },
        l: {
          alt: video.alt,
          poster: posterSrc,
          aspectRatio: '3/4',
          autoPlay: video.autoPlay,
          loop: video.loop,
          muted: video.muted,
          src: parsedSrc.src,
          nativeControls: video.controls,
        },
      },
    };
  }

  return {
    cover: true,
    playsInline: true,
    videos: {
      xs: {
        alt: video.alt,
        poster: posterSrc,
        aspectRatio: '3/4',
        autoPlay: video.autoPlay,
        loop: video.loop,
        muted: video.muted,
        src: parsedSrc.src,
        nativeControls: video.controls,
      },
    },
  };
}
