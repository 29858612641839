/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-undef */
import React from 'react';
import styled from 'styled-components';
import { SYS_BREAKPOINT_LG, ThemeProvider } from '@oneaudi/unified-web-common';

import type { AppContent, AssetType, ProportionTypes } from '../../types';
import { FA_LOCATOR_ID } from '../../types';
import { useTracking } from '../../utils/useTracking';
import Media from './Media';
import { LegalInfo } from '../LegalInfo/LegalInfo';
import { StyledEditorialMediaContainer } from './StyledEditorialMediaContainer';

const MediaWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${SYS_BREAKPOINT_LG}) {
    flex-direction: column;
  }

  .legal-info {
    @media (min-width: calc(${SYS_BREAKPOINT_LG} + 1px)) {
      display: none;
    }
  }
`;

const LegalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${SYS_BREAKPOINT_LG}) {
    display: none;
  }
`;

export const EditorialMedia: React.FC<AppContent> = (props) => {
  const {
    proportion,
    media1,
    caption1,
    consumptionAndEmissions1,
    disclaimers1,
    media2,
    caption2,
    consumptionAndEmissions2,
    disclaimers2,
    theme,
    hasMarginTop,
    hasMarginBottom,
  } = props;

  const ref = React.useRef(null);

  const tracking = useTracking(ref, {
    implementer: 37,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking(media2 ? [media1, media2] : [media1]);
  }, []);

  return (
    <ThemeProvider theme={{ name: theme === 'Dark' ? 'dark' : 'light' }}>
      <StyledEditorialMediaContainer
        ref={ref}
        data-test-id={FA_LOCATOR_ID}
        hasMarginTop={hasMarginTop}
        hasMarginBottom={hasMarginBottom}
      >
        {media1 &&
          createMedia(
            media1,
            caption1,
            consumptionAndEmissions1,
            disclaimers1,
            media2,
            caption2,
            consumptionAndEmissions2,
            disclaimers2,
            proportion,
          )}
      </StyledEditorialMediaContainer>
    </ThemeProvider>
  );
};

function createMedia(
  media1: AssetType,
  caption1?: string,
  consumptionAndEmissions1?: string[],
  disclaimers1?: string[],
  media2?: AssetType,
  caption2?: string,
  consumptionAndEmissions2?: string[],
  disclaimers2?: string[],
  proportion?: ProportionTypes,
) {
  if (!media2) {
    return [
      <MediaWrapper key="media-wrapper">
        <Media media={media1} position="left" />
        <LegalInfo
          caption={caption1}
          consumptionAndEmissions={consumptionAndEmissions1}
          disclaimers={disclaimers1}
          position="left"
        />
      </MediaWrapper>,
      <LegalWrapper key="legal-wrapper">
        <LegalInfo
          caption={caption1}
          consumptionAndEmissions={consumptionAndEmissions1}
          disclaimers={disclaimers1}
          position="left"
        />
      </LegalWrapper>,
    ];
  }

  const elements: JSX.Element[] = [];

  elements.push(
    <MediaWrapper key="media-wrapper">
      <Media
        media={media1}
        isLandscapeSize={proportion === '2-1 (Landscape-Portrait)'}
        position="left"
      />
      <LegalInfo
        isLandscapeSize={proportion === '2-1 (Landscape-Portrait)'}
        caption={caption1}
        consumptionAndEmissions={consumptionAndEmissions1}
        disclaimers={disclaimers1}
        position="left"
      />
      <Media
        media={media2}
        isLandscapeSize={proportion === '1-2 (Portrait-Landscape)'}
        position="right"
      />
      <LegalInfo
        isLandscapeSize={proportion === '1-2 (Portrait-Landscape)'}
        caption={caption2}
        consumptionAndEmissions={consumptionAndEmissions2}
        disclaimers={disclaimers2}
        position="right"
      />
    </MediaWrapper>,
  );

  elements.push(
    <LegalWrapper key="legal-wrapper">
      <LegalInfo
        isLandscapeSize={proportion === '2-1 (Landscape-Portrait)'}
        caption={caption1}
        consumptionAndEmissions={consumptionAndEmissions1}
        disclaimers={disclaimers1}
        position="left"
      />
      <LegalInfo
        isLandscapeSize={proportion === '1-2 (Portrait-Landscape)'}
        caption={caption2}
        consumptionAndEmissions={consumptionAndEmissions2}
        disclaimers={disclaimers2}
        position="right"
      />
    </LegalWrapper>,
  );

  return elements;
}
