/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';

import { ContentServiceV1 } from '@oneaudi/content-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';

import ContextProvider from './Context';
import App from './FeatureApp';
import { isTrackingDisabled } from './utils/debugging';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-video-service': AudiVideoServiceInterfaceV1;
  readonly 'audi-render-mode-service': RenderModeServiceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^2.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-video-service': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'locale-service': '^1.0.0',
    },
    externals: {
      '@oneaudi/unified-web-common': '^1.5.0',
    },
  },

  create: ({
    featureServices,
    featureAppId,
    featureAppName,
  }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');

    const contentService = featureServices['audi-content-service'];

    const trackingService = !isTrackingDisabled()
      ? (featureServices['audi-tracking-service'] as TrackingServiceV2)
      : undefined;
    if (trackingService) {
      trackingService.featureAppName = __FEATURE_APP_NAME__;
    }

    const vueFormatterService = featureServices['dbad:audi-vue-formatter-service'];
    const localeService = featureServices['locale-service'];

    const footnoteReferenceServiceScopeManager = featureServices['audi-footnote-reference-service'];
    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    const videoService = featureServices['audi-video-service'];

    const renderModeService = featureServices['audi-render-mode-service'];

    return {
      render: () => {
        return (
          <UniversalEditorProvider
            contentService={contentService}
            renderModeService={renderModeService}
          >
            <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
              <ContextProvider
                featureAppEnv={{ featureAppId, featureAppName }}
                localeService={localeService}
                loggerService={loggerService}
                trackingService={trackingService}
                videoService={videoService}
                vueFormatterService={vueFormatterService}
                renderModeService={renderModeService}
              >
                <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                  <App contentService={contentService} />
                </div>
              </ContextProvider>
            </FootnoteContextProvider>
          </UniversalEditorProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
